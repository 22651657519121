<template>
  <div
    class="x-sidebar-wrap flex-shrink-0"
    :class="{
      expanded: sidebarExpanded,
    }"
  >
    <div class="x-sidebar-background" @click="sidebarExpanded = false" />
    <div class="x-sidebar">
      <div class="x-sidebar-inner">
        <ul class="nav nav-pills flex-column mb-auto flex-nowrap">
          <AppSidebarLink v-for="item of items" :key="item.name" v-bind="item" />
        </ul>
        <div class="x-sidebar-minimizer">
          <button
            class="btn bi"
            :class="sidebarMinimized ? 'bi-chevron-double-right' : 'bi-chevron-double-left'"
            @click.prevent="() => (sidebarMinimized = !sidebarMinimized)"
          />
        </div>
        <ul class="nav nav-pills flex-column flex-nowrap">
          <AppSidebarLink v-for="item of footerItems" :key="item.name" v-bind="item" />
          <li class="version mt-2">
            <a class="nav-link">
              <i class="nav-link-icon bi bi-git" /><span class="nav-link-title">{{ version }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { onKeyStroke } from '@vueuse/core';
// @TODO Importing this from bootstrap might not be the most efficient method
// eslint-disable-next-line
import { Dropdown } from 'bootstrap';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useAppStore } from '../stores/app';
import { useAuthStore } from '../stores/auth';
import { hasFlag, hasPermission } from '../utilities/auth';
import AppSidebarLink from './AppSidebarLink.vue';
const authStore = useAuthStore();
const { account } = storeToRefs(authStore);
const { isTM, version, appData, sidebarExpanded, sidebarMinimized } = storeToRefs(useAppStore());

const isExternalAuditor = computed(() => {
  return authStore.account?.roles?.includes('ExternalAuditor');
});

const isDESNZViewer = computed(() => {
  return authStore.account?.roles?.includes('DESNZRetrofitDataViewer');
});

const isOfgemRetrofitDataViewer = computed(() => {
  return authStore.account?.roles?.includes('OfgemRetrofitDataViewer');
});

const router = useRouter();
const filterByAuth = (item) => {
  if (item.to) {
    try {
      let shouldBlock = false;

      // If the item has a a block flag
      if (isExternalAuditor.value && item?.flags?.blockExternalAuditor) {
        shouldBlock = true;
      }
      if (isDESNZViewer.value && item?.flags?.blockDESNZViewer) {
        shouldBlock = true;
      }
      if (isOfgemRetrofitDataViewer.value && item?.flags?.blockOfgemViewer) {
        shouldBlock = true;
      }
      if (shouldBlock) {
        return false;
      }

      const { meta } = router.resolve({ name: item.to });
      // If the item has a blockExternalAuditor flag, check if the user is an external auditor
      if (isExternalAuditor.value && meta?.flags?.blockExternalAuditor) {
        shouldBlock = true;
      }
      if (isDESNZViewer.value && meta?.flags?.blockDESNZViewer) {
        shouldBlock = true;
      }
      if (isOfgemRetrofitDataViewer.value && meta?.flags?.blockOfgemViewer) {
        shouldBlock = true;
      }
      if (shouldBlock) {
        return false;
      }

      if (item.flags) {
        return hasFlag(appData.value?.flags, item.flags) !== false;
      }

      // If the item has flags or permissions, check if the user has them
      const results = [
        hasFlag(appData.value?.flags, meta?.flags),
        hasPermission(account.value.roles, meta?.permissions),
      ];
      return results.every((r) => r === true || r === null);
    } catch {
      return false;
    }
  }
  if (item.flags) {
    return hasFlag(appData.value?.flags, item.flags) !== false;
  }
  return true;
};

const items = [
  { icon: 'speedometer2', to: 'dashboard', title: 'Dashboard' },
  {
    icon: 'card-checklist',
    to: 'assessments',
    title: 'Assessments',
    flags: { blockDESNZViewer: true, blockOfgemViewer: true },
  },
  { icon: 'house', to: 'projects', title: 'Projects' },
  { icon: 'pencil-square', to: 'lodgements', title: 'Lodgements' },
  { icon: 'chat-quote', to: 'responses', title: 'Responses' },
  { icon: 'cloud-download', to: 'exports', title: 'Exports' },
  { icon: 'clipboard-check', to: 'compliance', title: 'Compliance' },
  { icon: 'bank', to: 'finance', title: 'Finance' },
  { icon: 'activity', to: 'ofgem', title: 'Ofgem' },
  { icon: 'search', to: 'uprn-search', title: 'UPRN Search' },
  {
    icon: 'book',
    href: 'https://www.trustmark.org.uk/support/dw/general',
    title: 'Documentation',
    flags: { isTrustmark: false },
  },
].filter(filterByAuth);

const footerItems = [
  {
    icon: isTM.value ? 'badge-tm' : 'person',
    to: 'account',
    title: 'Account',
  },
  { icon: 'box-arrow-right', to: 'logout', title: 'Logout' },
].filter(filterByAuth);

onKeyStroke('Escape', () => {
  if (sidebarExpanded.value) {
    sidebarExpanded.value = false;
  }
});
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: 200ms ease padding;
  > li {
    width: 100%;
  }
}
.nav-link {
  width: 100%;

  white-space: nowrap;
  color: $gray-700;
  &:not(.active) {
    &:hover,
    &:focus {
      color: $black;
    }
  }
}

.nav-link-icon {
  font-size: $font-size-base;
}

.nav-link-title {
  margin-left: 0.5em;
}

.nav-pills {
  overflow-y: auto;
}
</style>
<style lang="scss">
.x-sidebar-wrap {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 200ms ease;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: $zindex-sidebar;
  width: 100%;
  &.expanded {
    visibility: visible;
    opacity: 1;
    .x-sidebar {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.x-sidebar-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.x-sidebar {
  height: 100%;
  padding-top: $navbar-height;
  background-color: $gray-100;
  color: $gray-200;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  max-width: 500px;
  box-shadow: inset 1px 0 0 $border-color;
  opacity: 0;
  transform: translateX(10%);
  transition: 200ms ease transform;
}

.x-sidebar-inner {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.x-sidebar-minimizer {
  display: none;
  position: relative;
  margin-bottom: 1rem;
  > button {
    padding: 0;
    color: $gray-500;
    width: 2rem;
    height: 2rem;
    border-radius: $border-radius-pill;
    border: 1px solid $border-color;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(50%);
    background-color: $light;
    &:hover,
    &:focus {
      border-color: $primary;
      background-color: $primary;
      color: $white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .x-sidebar-wrap {
    transition: none;
    opacity: 1;
    visibility: visible;
    left: 0;
    right: auto;
    width: $sidebar-width;
    transition: 200ms ease width;
  }
  .sidebar-minimized,
  .drawer-active {
    .x-sidebar-wrap {
      width: $sidebar-width-mini;
      .nav {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      .nav-link-title {
        display: none;
      }
    }
  }
  .drawer-active {
    .x-sidebar-minimizer {
      display: none;
    }
  }
  .x-sidebar {
    transform: none;
    transition: none;
    opacity: 1;
    width: 100%;
    max-width: 100%;
    box-shadow: inset -1px 0 0 $border-color;
  }
  .x-sidebar-minimizer {
    display: block;
  }
}

.version {
  .nav-link-icon {
    font-size: 0.7rem;
  }

  .nav-link,
  .nav-link:not(.active):hover {
    color: $secondary;
    font-size: 0.7rem;
  }
}
</style>
